<template>
  <router-view class="productRouterClass" />
</template>

<script>
export default {
  name: "product",
};
</script>

<style scoped>
.productRouterClass {
  width: 100%;
  min-width: 1200px;
}
</style>
